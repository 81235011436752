import { default as _91slug_93AuGzloTplTMeta } from "D:/WS Intern/WS Plattform/2024/24-7963 Walbert-Schmitz Website/04 - Development/ws-website-frontend/pages/[slug].vue?macro=true";
import { default as _404OeUyKSELyVMeta } from "D:/WS Intern/WS Plattform/2024/24-7963 Walbert-Schmitz Website/04 - Development/ws-website-frontend/pages/404.vue?macro=true";
import { default as aboutXozAacFbVgMeta } from "D:/WS Intern/WS Plattform/2024/24-7963 Walbert-Schmitz Website/04 - Development/ws-website-frontend/pages/about.vue?macro=true";
import { default as contactbJFrDsg7neMeta } from "D:/WS Intern/WS Plattform/2024/24-7963 Walbert-Schmitz Website/04 - Development/ws-website-frontend/pages/contact.vue?macro=true";
import { default as indexo9KtK8SAOuMeta } from "D:/WS Intern/WS Plattform/2024/24-7963 Walbert-Schmitz Website/04 - Development/ws-website-frontend/pages/index.vue?macro=true";
import { default as kaleidoscopex8kMf6ehhBMeta } from "D:/WS Intern/WS Plattform/2024/24-7963 Walbert-Schmitz Website/04 - Development/ws-website-frontend/pages/kaleidoscope.vue?macro=true";
import { default as data_45privacyEVC5mo17iZMeta } from "D:/WS Intern/WS Plattform/2024/24-7963 Walbert-Schmitz Website/04 - Development/ws-website-frontend/pages/services/data-privacy.vue?macro=true";
import { default as imprintBB9sPPKBCuMeta } from "D:/WS Intern/WS Plattform/2024/24-7963 Walbert-Schmitz Website/04 - Development/ws-website-frontend/pages/services/imprint.vue?macro=true";
import { default as terms_45and_45services95HQCN1OxIMeta } from "D:/WS Intern/WS Plattform/2024/24-7963 Walbert-Schmitz Website/04 - Development/ws-website-frontend/pages/services/terms-and-services.vue?macro=true";
import { default as sustainabilitynyyVdxZzQpMeta } from "D:/WS Intern/WS Plattform/2024/24-7963 Walbert-Schmitz Website/04 - Development/ws-website-frontend/pages/sustainability.vue?macro=true";
import { default as urban_45brand_45spacehuvY4B31ExMeta } from "D:/WS Intern/WS Plattform/2024/24-7963 Walbert-Schmitz Website/04 - Development/ws-website-frontend/pages/urban-brand-space.vue?macro=true";
export default [
  {
    name: _91slug_93AuGzloTplTMeta?.name ?? "slug___de",
    path: _91slug_93AuGzloTplTMeta?.path ?? "/de/:slug()/",
    meta: _91slug_93AuGzloTplTMeta || {},
    alias: _91slug_93AuGzloTplTMeta?.alias || [],
    redirect: _91slug_93AuGzloTplTMeta?.redirect,
    component: () => import("D:/WS Intern/WS Plattform/2024/24-7963 Walbert-Schmitz Website/04 - Development/ws-website-frontend/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93AuGzloTplTMeta?.name ?? "slug___en",
    path: _91slug_93AuGzloTplTMeta?.path ?? "/en/:slug()/",
    meta: _91slug_93AuGzloTplTMeta || {},
    alias: _91slug_93AuGzloTplTMeta?.alias || [],
    redirect: _91slug_93AuGzloTplTMeta?.redirect,
    component: () => import("D:/WS Intern/WS Plattform/2024/24-7963 Walbert-Schmitz Website/04 - Development/ws-website-frontend/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _404OeUyKSELyVMeta?.name ?? "404___de",
    path: _404OeUyKSELyVMeta?.path ?? "/de/404/",
    meta: _404OeUyKSELyVMeta || {},
    alias: _404OeUyKSELyVMeta?.alias || [],
    redirect: _404OeUyKSELyVMeta?.redirect,
    component: () => import("D:/WS Intern/WS Plattform/2024/24-7963 Walbert-Schmitz Website/04 - Development/ws-website-frontend/pages/404.vue").then(m => m.default || m)
  },
  {
    name: _404OeUyKSELyVMeta?.name ?? "404___en",
    path: _404OeUyKSELyVMeta?.path ?? "/en/404/",
    meta: _404OeUyKSELyVMeta || {},
    alias: _404OeUyKSELyVMeta?.alias || [],
    redirect: _404OeUyKSELyVMeta?.redirect,
    component: () => import("D:/WS Intern/WS Plattform/2024/24-7963 Walbert-Schmitz Website/04 - Development/ws-website-frontend/pages/404.vue").then(m => m.default || m)
  },
  {
    name: aboutXozAacFbVgMeta?.name ?? "about___de",
    path: aboutXozAacFbVgMeta?.path ?? "/de/about/",
    meta: aboutXozAacFbVgMeta || {},
    alias: aboutXozAacFbVgMeta?.alias || [],
    redirect: aboutXozAacFbVgMeta?.redirect,
    component: () => import("D:/WS Intern/WS Plattform/2024/24-7963 Walbert-Schmitz Website/04 - Development/ws-website-frontend/pages/about.vue").then(m => m.default || m)
  },
  {
    name: aboutXozAacFbVgMeta?.name ?? "about___en",
    path: aboutXozAacFbVgMeta?.path ?? "/en/about/",
    meta: aboutXozAacFbVgMeta || {},
    alias: aboutXozAacFbVgMeta?.alias || [],
    redirect: aboutXozAacFbVgMeta?.redirect,
    component: () => import("D:/WS Intern/WS Plattform/2024/24-7963 Walbert-Schmitz Website/04 - Development/ws-website-frontend/pages/about.vue").then(m => m.default || m)
  },
  {
    name: contactbJFrDsg7neMeta?.name ?? "contact___de",
    path: contactbJFrDsg7neMeta?.path ?? "/de/contact/",
    meta: contactbJFrDsg7neMeta || {},
    alias: contactbJFrDsg7neMeta?.alias || [],
    redirect: contactbJFrDsg7neMeta?.redirect,
    component: () => import("D:/WS Intern/WS Plattform/2024/24-7963 Walbert-Schmitz Website/04 - Development/ws-website-frontend/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactbJFrDsg7neMeta?.name ?? "contact___en",
    path: contactbJFrDsg7neMeta?.path ?? "/en/contact/",
    meta: contactbJFrDsg7neMeta || {},
    alias: contactbJFrDsg7neMeta?.alias || [],
    redirect: contactbJFrDsg7neMeta?.redirect,
    component: () => import("D:/WS Intern/WS Plattform/2024/24-7963 Walbert-Schmitz Website/04 - Development/ws-website-frontend/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: indexo9KtK8SAOuMeta?.name ?? "index___de",
    path: indexo9KtK8SAOuMeta?.path ?? "/de/",
    meta: indexo9KtK8SAOuMeta || {},
    alias: indexo9KtK8SAOuMeta?.alias || [],
    redirect: indexo9KtK8SAOuMeta?.redirect,
    component: () => import("D:/WS Intern/WS Plattform/2024/24-7963 Walbert-Schmitz Website/04 - Development/ws-website-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexo9KtK8SAOuMeta?.name ?? "index___en",
    path: indexo9KtK8SAOuMeta?.path ?? "/en/",
    meta: indexo9KtK8SAOuMeta || {},
    alias: indexo9KtK8SAOuMeta?.alias || [],
    redirect: indexo9KtK8SAOuMeta?.redirect,
    component: () => import("D:/WS Intern/WS Plattform/2024/24-7963 Walbert-Schmitz Website/04 - Development/ws-website-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: kaleidoscopex8kMf6ehhBMeta?.name ?? "kaleidoscope___de",
    path: kaleidoscopex8kMf6ehhBMeta?.path ?? "/de/kaleidoscope/",
    meta: kaleidoscopex8kMf6ehhBMeta || {},
    alias: kaleidoscopex8kMf6ehhBMeta?.alias || [],
    redirect: kaleidoscopex8kMf6ehhBMeta?.redirect,
    component: () => import("D:/WS Intern/WS Plattform/2024/24-7963 Walbert-Schmitz Website/04 - Development/ws-website-frontend/pages/kaleidoscope.vue").then(m => m.default || m)
  },
  {
    name: kaleidoscopex8kMf6ehhBMeta?.name ?? "kaleidoscope___en",
    path: kaleidoscopex8kMf6ehhBMeta?.path ?? "/en/kaleidoscope/",
    meta: kaleidoscopex8kMf6ehhBMeta || {},
    alias: kaleidoscopex8kMf6ehhBMeta?.alias || [],
    redirect: kaleidoscopex8kMf6ehhBMeta?.redirect,
    component: () => import("D:/WS Intern/WS Plattform/2024/24-7963 Walbert-Schmitz Website/04 - Development/ws-website-frontend/pages/kaleidoscope.vue").then(m => m.default || m)
  },
  {
    name: data_45privacyEVC5mo17iZMeta?.name ?? "services-data-privacy___de",
    path: data_45privacyEVC5mo17iZMeta?.path ?? "/de/services/data-privacy/",
    meta: data_45privacyEVC5mo17iZMeta || {},
    alias: data_45privacyEVC5mo17iZMeta?.alias || [],
    redirect: data_45privacyEVC5mo17iZMeta?.redirect,
    component: () => import("D:/WS Intern/WS Plattform/2024/24-7963 Walbert-Schmitz Website/04 - Development/ws-website-frontend/pages/services/data-privacy.vue").then(m => m.default || m)
  },
  {
    name: data_45privacyEVC5mo17iZMeta?.name ?? "services-data-privacy___en",
    path: data_45privacyEVC5mo17iZMeta?.path ?? "/en/services/data-privacy/",
    meta: data_45privacyEVC5mo17iZMeta || {},
    alias: data_45privacyEVC5mo17iZMeta?.alias || [],
    redirect: data_45privacyEVC5mo17iZMeta?.redirect,
    component: () => import("D:/WS Intern/WS Plattform/2024/24-7963 Walbert-Schmitz Website/04 - Development/ws-website-frontend/pages/services/data-privacy.vue").then(m => m.default || m)
  },
  {
    name: imprintBB9sPPKBCuMeta?.name ?? "services-imprint___de",
    path: imprintBB9sPPKBCuMeta?.path ?? "/de/services/imprint/",
    meta: imprintBB9sPPKBCuMeta || {},
    alias: imprintBB9sPPKBCuMeta?.alias || [],
    redirect: imprintBB9sPPKBCuMeta?.redirect,
    component: () => import("D:/WS Intern/WS Plattform/2024/24-7963 Walbert-Schmitz Website/04 - Development/ws-website-frontend/pages/services/imprint.vue").then(m => m.default || m)
  },
  {
    name: imprintBB9sPPKBCuMeta?.name ?? "services-imprint___en",
    path: imprintBB9sPPKBCuMeta?.path ?? "/en/services/imprint/",
    meta: imprintBB9sPPKBCuMeta || {},
    alias: imprintBB9sPPKBCuMeta?.alias || [],
    redirect: imprintBB9sPPKBCuMeta?.redirect,
    component: () => import("D:/WS Intern/WS Plattform/2024/24-7963 Walbert-Schmitz Website/04 - Development/ws-website-frontend/pages/services/imprint.vue").then(m => m.default || m)
  },
  {
    name: terms_45and_45services95HQCN1OxIMeta?.name ?? "services-terms-and-services___de",
    path: terms_45and_45services95HQCN1OxIMeta?.path ?? "/de/services/terms-and-services/",
    meta: terms_45and_45services95HQCN1OxIMeta || {},
    alias: terms_45and_45services95HQCN1OxIMeta?.alias || [],
    redirect: terms_45and_45services95HQCN1OxIMeta?.redirect,
    component: () => import("D:/WS Intern/WS Plattform/2024/24-7963 Walbert-Schmitz Website/04 - Development/ws-website-frontend/pages/services/terms-and-services.vue").then(m => m.default || m)
  },
  {
    name: terms_45and_45services95HQCN1OxIMeta?.name ?? "services-terms-and-services___en",
    path: terms_45and_45services95HQCN1OxIMeta?.path ?? "/en/services/terms-and-services/",
    meta: terms_45and_45services95HQCN1OxIMeta || {},
    alias: terms_45and_45services95HQCN1OxIMeta?.alias || [],
    redirect: terms_45and_45services95HQCN1OxIMeta?.redirect,
    component: () => import("D:/WS Intern/WS Plattform/2024/24-7963 Walbert-Schmitz Website/04 - Development/ws-website-frontend/pages/services/terms-and-services.vue").then(m => m.default || m)
  },
  {
    name: sustainabilitynyyVdxZzQpMeta?.name ?? "sustainability___de",
    path: sustainabilitynyyVdxZzQpMeta?.path ?? "/de/sustainability/",
    meta: sustainabilitynyyVdxZzQpMeta || {},
    alias: sustainabilitynyyVdxZzQpMeta?.alias || [],
    redirect: sustainabilitynyyVdxZzQpMeta?.redirect,
    component: () => import("D:/WS Intern/WS Plattform/2024/24-7963 Walbert-Schmitz Website/04 - Development/ws-website-frontend/pages/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilitynyyVdxZzQpMeta?.name ?? "sustainability___en",
    path: sustainabilitynyyVdxZzQpMeta?.path ?? "/en/sustainability/",
    meta: sustainabilitynyyVdxZzQpMeta || {},
    alias: sustainabilitynyyVdxZzQpMeta?.alias || [],
    redirect: sustainabilitynyyVdxZzQpMeta?.redirect,
    component: () => import("D:/WS Intern/WS Plattform/2024/24-7963 Walbert-Schmitz Website/04 - Development/ws-website-frontend/pages/sustainability.vue").then(m => m.default || m)
  },
  {
    name: urban_45brand_45spacehuvY4B31ExMeta?.name ?? "urban-brand-space___de",
    path: urban_45brand_45spacehuvY4B31ExMeta?.path ?? "/de/urban-brand-space/",
    meta: urban_45brand_45spacehuvY4B31ExMeta || {},
    alias: urban_45brand_45spacehuvY4B31ExMeta?.alias || [],
    redirect: urban_45brand_45spacehuvY4B31ExMeta?.redirect,
    component: () => import("D:/WS Intern/WS Plattform/2024/24-7963 Walbert-Schmitz Website/04 - Development/ws-website-frontend/pages/urban-brand-space.vue").then(m => m.default || m)
  },
  {
    name: urban_45brand_45spacehuvY4B31ExMeta?.name ?? "urban-brand-space___en",
    path: urban_45brand_45spacehuvY4B31ExMeta?.path ?? "/en/urban-brand-space/",
    meta: urban_45brand_45spacehuvY4B31ExMeta || {},
    alias: urban_45brand_45spacehuvY4B31ExMeta?.alias || [],
    redirect: urban_45brand_45spacehuvY4B31ExMeta?.redirect,
    component: () => import("D:/WS Intern/WS Plattform/2024/24-7963 Walbert-Schmitz Website/04 - Development/ws-website-frontend/pages/urban-brand-space.vue").then(m => m.default || m)
  }
]