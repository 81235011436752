<template>
  <menu
    :class="{
      'c-main-menu': props.menuType === 'mainMenu',
      'c-service-menu': props.menuType === 'serviceMenu',
    }"
  >
    <img
      v-if="props.menuType === 'mainMenu'" 
      class='menu-flow'
      :src="'/blobs/Flow_Menu_APNG_256.png'"
      alt="">

    <li v-if="props.menuType === 'mainMenu'">
      <NuxtLink 
        :to="localePath('/')" 
        :title="'Home'" 
        :data-menupoint="0"
        @click="toggleMenu(false)"
        @mouseover="changeFlowPosition(0)"
        @mouseleave="setFlowPosition()"
      >
        Home
      </NuxtLink>
    </li>

    <li v-for="(menuItem, index) in currentMenu" :key="menuItem.id">
      <NuxtLink
        :to="
          props.menuType === 'serviceMenu'
            ? localePath(`/services/${menuItem.route}`)
            : localePath(`/${menuItem.route}`)
        "
        :title="menuItem.title"
        :data-menupoint="index + 1"
        @click="toggleMenu(false)"
        @mouseover="props.menuType === 'mainMenu' ? changeFlowPosition(index + 1) : null"
        @mouseleave="props.menuType === 'mainMenu' ? setFlowPosition() : null"
      >
        {{ menuItem.title }}
      </NuxtLink>
    </li>

    <li v-if="props.menuType === 'serviceMenu'">
      <button @click="toggleCookieBanner(true)">
        {{ $t("cookieButton") }}
      </button>
    </li>

    <li v-if="props.menuType === 'serviceMenu'">
      <button @click="toggleModalContainer(true)">
        {{ $t("newsSignIn") }}
      </button>
    </li>
  </menu>
</template>

<script setup>
  import { useI18n } from "#imports";
  import { storeToRefs } from "pinia";
  import { useMenuStore } from "@/stores/menues";

  const { $bus } = useNuxtApp();

  const store = useMenuStore();
  const { mainMenu, serviceMenu } = storeToRefs(store);

  const localePath = useLocalePath();

  const props = defineProps({
    menuType: String,
  });

  const toggleMenu = (value) => {
    $bus.$emit("toggleMenu", value);
  };

  const toggleModalContainer = (value) => {
    $bus.$emit("closeMarketingModal", { state: value });
  }

  const toggleCookieBanner = (value) => {
    $bus.$emit("openCookieConsent", { state: value });
  };

  const { locale } = useI18n();
  const processID = "1022";

  store.setMenues(locale.value, processID);

  watch(locale, () => {
    store.setMenues(locale.value, processID);
  });

  const currentMenu = props.menuType === "serviceMenu" ? serviceMenu : mainMenu;

  const changeFlowPosition = (offset) => {
    const flow = document.querySelector('.menu-flow')
    const additionalOffset = -60
    const menuLinkHeight =  window.innerWidth >= 1440 ? 94 :
                            window.innerWidth >= 1024 ? 80 : 62

    flow.style.top = (offset * menuLinkHeight) + additionalOffset + 'px'
  }

  const setFlowPosition = () => {
      const activePoint = document.querySelector('.c-main-menu .router-link-exact-active')
      
      changeFlowPosition(Number(activePoint.dataset.menupoint))
  }

  nextTick(() => {
    if (props.menuType === 'mainMenu') {
      setFlowPosition()
    }
  })

</script>

<style lang="scss" scoped>
  .c-main-menu {
    position: relative;

    .menu-flow {
      position: absolute;
      left: -160px;
      width: 220px;
      z-index: -1;
      top: -60px;
      transition: top 1.2s ease;

      @media screen and (max-width: 640px) {
        left: initial;
        right: -44px;
        width: 180px;
      }
    }
  }
</style>